import React from "react";

const OurFounder = () => {
    const containerStyle = {
        padding: "30px",
        maxWidth: "800px",
        margin: "auto",
        fontFamily: "'Arial', sans-serif",
        lineHeight: "1.8",
        color: "#333",
    };

    const sectionStyle = {
        marginBottom: "10px",
    };

    const headingStyle = {
        marginBottom: "5px",
        color: "#2c3e50",
        fontWeight: "bold",
    };

    const listStyle = {
        marginLeft: "20px",
        lineHeight: "1.8",
    };

    const paragraphStyle = {
        marginBottom: "10px",
        textAlign: "justify",
    };

    return (
        <div style={containerStyle}>

            <h1 style={{ textAlign: "center", marginBottom: "10px" }}>Hi, I’m Aliya, the Founder of Rate My Courses</h1>

            {/* Image section */}
            <section style={{ textAlign: "center", marginBottom: "10px"}}>
                <img
                    src="/aliya_ourfounder.png"
                    alt="Aliya, Founder of Rate My Courses"
                    style={{
                        width: "80%",
                        height: "auto",
                        objectFit: "cover",
                        aspectRatio: "1 / 1",
                        display: "block",
                        margin: "auto",
                    }}
                />
            </section>

            <section style={sectionStyle}>
                <p style={paragraphStyle}>
                    Like many of you, I struggled every semester when it came to course selection. I switched majors multiple times,
                    relied heavily on platforms like Reddit and Rate My Professor, yet still found myself second-guessing my choices.

                </p>
                <p style={paragraphStyle}>
                    Starting university during the COVID-19 pandemic added to the challenge. With my first two years entirely online, I
                    had limited access to upper-year students who could offer guidance. As an international student paying $6,000 per
                    course, I didn’t have the luxury of dropping a course mid-semester without facing significant financial loss.
                </p>
                <p style={paragraphStyle}>
                    The problem wasn’t just financial—it was also a lack of resources. Course syllabus barely scratched the surface of
                    what I needed to know, and the full refund drop deadline wasn’t enough time to decide if a course was the right fit.
                </p>
                <p>
                    And I wasn’t alone. Every semester, group chats would boom with questions:
                </p>

                <ul style={listStyle}>
                    <li>“Is NROB60 a hard course?”</li>
                    <li>“How’s the workload for MGEA06?”</li>
                    <li>“Do I need to do the readings for the final?”</li>
                    <li>“Are lectures recorded?”</li>
                    <li>“How’s the professor?”</li>
                </ul>
                <p>Yet there was no centralized platform with all the answers.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>How Rate My Courses was born</h2>
                <p style={paragraphStyle}>
                    I approached UofT about creating a course review platform to help students, but faced a lot of pushback. The student
                    union? They didn’t have capacity.
                </p>
                <p>
                    But I wasn’t willing to accept no as an answer—especially when I knew there were others that struggled with the same
                    challenges I faced. That’s why we created <strong>Rate My Courses</strong>.
                </p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>What Is Rate My Courses?</h2>
                <p>
                    <strong>Rate My Courses</strong> is the platform I wish I had as a student. It’s a space where students can share
                    honest reviews, offer advice, and make course selection easier for everyone. Even though some of us have already
                    graduated, we want to ensure that future students don’t face the same challenges we did.
                </p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Join us today!</h2>
                <p>Share your course reviews and help others make smarter, stress-free academic choices.</p>
            </section>
        </div>
    );
};

export default OurFounder;
